import AddPageModal from "../../components/AddPage/AddPage";
import {useEffect, useState} from "react";
import http from "../../http";
import {message} from "antd";
import {useTranslation} from "react-i18next";


const MachineAddPage=({visible, onClose, onSuccessSubmit, onErrorSubmit, edit, editItem})=>{
    const [initialValues, setInitialValues] = useState({})
    const defaultValues = {machine_type: 1};
    const { t } = useTranslation();
    const config ={
        addUrl: '/api/v1/machines/add/',
        editUrl: edit ? `/api/v1/machines/${editItem.id}/edit/` : '',
        dataUrl: edit? `/api/v1/machines/${editItem.id}/` : '',
        pageTitle: !edit ? t('machineaddpgae_title') : t('addpage_edit_button_text') + ' ' +`${editItem.name}`,
        colNo: 2,
        cancelButtonText: t('addpage_cancel_button_text'),
        saveButtonText: !edit ? t('addpage_save_button_text') : t('addpage_edit_button_text'),
        addSuccessMessage: !edit ? t('machine_add_successful_message') : t('machine_edit_successful_message'),
        fields: [
            {
                name: 'name',
                label: t('machine_name_field'),
                placeholder: t('machine_name_field_placeholder'),
                isRequired: true,
                requiredMessage: t('machine_name_required_text'),
                type: 'input',
                colNo: 1,
                order: 1,
                hidden: initialValues.lease_status
            },
            {
                name: 'machine_type',
                label: t('machine_type_field'),
                placeholder: t('machine_machinetype_field_placeholder'),
                isRequired: true,
                requiredMessage: t('machine_machinetype_required_text'),
                type: 'select',
                colNo: 1,
                order: 1,
                extraProps: {
                    url: '/api/v1/enums/MachineTypeChoices/combo/',
                    withTranslation: true
                },
                hidden: initialValues.lease_status
            },
            {
                name: 'number',
                label: t('machine_carnumber_field'),
                placeholder: t('machine_carnumber_field_placeholder'),
                isRequired: true,
                requiredMessage: t('machine_carnumber_field_required_text'),
                type: 'input',
                colNo: 1,
                order: 2,
                extraProps: {
                    disabled: edit
                }
            },
            {
                name: 'year_made',
                label: t('machine_yearmade_field'),
                placeholder: t('machine_yearmade_field_placeholder'),
                isRequired: false,
                requiredMessage: '',
                type: 'input',
                colNo: 1,
                order: 3,
                hidden: initialValues.lease_status
            },
            {
                name: 'assigned',
                label: t('machine_assignto_field'),
                placeholder: t('machine_assignto_field_placeholder'),
                isRequired: false,
                requiredMessage: '',
                type: 'select',
                colNo: 2,
                order: 1,
                extraProps: {
                    url: '/api/v1/auth/users/combo/'
                },
            },
            {
                name: 'model',
                label: t('machine_model_field'),
                placeholder: t('machine_model_field_placeholder'),
                isRequired: false,
                requiredMessage: '',
                type: 'input',
                colNo: 2,
                order: 2,
                hidden: initialValues.lease_status
            },
            {
                name: 'manufacture',
                label: t('machine_manufacturer_field'),
                placeholder: t('machine_manufacturer_field_placeholder'),
                isRequired: false,
                requiredMessage: '',
                type: 'input',
                colNo: 2,
                order: 3,
                hidden: initialValues.lease_status
            },
            {
                name: 'vrs',
                label: t('machine_vrs_field'),
                placeholder: t('machine_vrs_field_placeholder'),
                isRequired: false,
                requiredMessage: '',
                type: 'select',
                extraProps: {
                    url: editItem ? `/api/v1/machines/vrs/combo/?machine_id=${editItem.id}`: '/api/v1/machines/vrs/combo/'
                },
                colNo: 2,
                order: 3
            },
            {
                name: 'base_position',
                label:  t('machine_base_location_field'),
                placeholder: 'Enter Lat, Long',
                isRequired: false,
                requiredMessage: '',
                type: 'map',
                colNo: 2,
                order: 4,
            },
        ]
    }

    useEffect(()=>{
        setInitialValues({});
        if(editItem){
            let dataUrl = `/api/v1/machines/${editItem.id}/`;
            http.get(dataUrl).then((resp)=>{
                setInitialValues(resp.data);
            }).catch((err)=>{
                message.error('Loading Data Failed')
            })
        }
    }, [editItem])

    const getWarningMessage=()=>{
        if(edit && editItem){
            if(editItem?.has_running_contract){
                return t('machine_vrs_change_warning')
            }
        }
        return null;
    }

    return <AddPageModal
        config={config}
        visible={visible}
        onClose={onClose}
        onSuccessSubmit={onSuccessSubmit}
        onErrorSubmit={onErrorSubmit}
        edit={edit}
        initialValues={initialValues}
        defaultValues={defaultValues}
        warningMessage={getWarningMessage()}
    />

}

export default MachineAddPage;
