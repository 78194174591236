import {Divider, Row, Col, Space, Table, Tooltip, Typography, message} from "antd";
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import http from "../../http";
import {useTranslation} from "react-i18next";
import DataTable from "../../components/DataTable/DataTable";
import PermissionChecker from "../../PermissionChecker";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";
import {CloseOutlined} from "@ant-design/icons";


const TerminalDeviceDetailPage=(props)=>{
    const navigate = useNavigate();
    const location = useLocation();
    const {state} = location;
    const [loading, setLoading] = useState(false);
    const [terminal_device, setTerminalDevice] = useState(state?.terminal_device);
    const [isValidState, setValidState] = useState(!!state?.terminal_device);
    const [reload, setReload] = useState(false);
    const { t } = useTranslation();
    const machineColumns = [
        {
            title: 'Machine ID',
            dataIndex: 'machine_id',
            key: 'machine_id',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            ellipsis: true,
            render: (text) => (
                <Tooltip title={text} placement={'leftTop'}>
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-all', color: text === 'Expired' ? '#DC000C': '#079327' }}>{text}</div>
                </Tooltip>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            fixed:'right',
            width: '10%',
            align: 'center',
            render: (record) => (
                <Space size="middle">
                    {renderMachineExpireIcon(record)}
                </Space>
            )
        },
    ]

    const renderMachineExpireIcon=(record)=>{
        if(record.status == 'Active'){
        return <CustomConfirmPopup
                    title={t("machine_binding_de_registration_confirm_text")}
                    onConfirm={()=>handleMachineBindingExpire(record)}
                    icon={<CloseOutlined style={{color: '#DC000C'}}/>}
                    okText={t('yes_text')}
                    cancelText={t('no_text')}
                    okType='default'
                >
                    <PermissionChecker requiredPermissions={["expire-machine-binding"]}><Typography.Link ><CloseOutlined style={{color: '#DC000C'}}/></Typography.Link></PermissionChecker>
                </CustomConfirmPopup>

        }
        return null
    }

    const handleMachineBindingExpire=(record)=>{
        setReload(true);
        http.post(`/api/v1/auth/tenants/terminal_devices/${record.id}/expire_machine_binding/`).then((resp)=>{
                if (resp.status === 200) {
                    message.success(t(resp?.data?.message))
                    setReload(false);
                }
                else{
                    setReload(false);
                    message.error(t(resp?.data?.message))
                }
            }).catch((err)=>{
                setReload(false);
                message.error(t("machine_binding_de_registration_failed_text"))
            })
    }

    const fetchTerminalDeviceData=()=>{
        http.get(`/api/v1/auth/tenants/terminal_devices/${terminal_device?.id}/?`).then((resp)=>{
            setLoading(false);
            console.log(resp.data)
            setTerminalDevice(resp.data);
        }).catch((err)=>{
            setLoading(false);
        })
    }

    useEffect(()=>{
        if(!state){
            navigate('/content_not_found',{replace: true})
        }
        if(isValidState) {
            fetchTerminalDeviceData()
        }
    }, [state])

    const buildBreadcrumbs=()=>{
        let breadcrumbs = [];
        if(state.breadcrumbs){
            state.breadcrumbs.map((item, index)=>{
                breadcrumbs.push(
                    <><Link to={item.to} state={item.state} key={index}>{item.title}</Link> > </>
                )
            })
            breadcrumbs.push(<>{terminal_device.device_name}</>)
            return breadcrumbs;
        }
        return <><Link to={'/terminal_devices'}>{t('terminal_device_listpage_title')}  </Link> >  {terminal_device.device_name}</>;
    }
    if(isValidState) {
        return (
            <>
                <div style={{overflowY: 'auto', overflowX: 'hidden', width: '100%', height: '100%'}}>
                    <Row style={{height: '5%', color: "black", fontWeight: 'bold', justifyContent: 'space-between'}}>
                        <Col>
                            <Space>{buildBreadcrumbs()}</Space>
                        </Col>
                    </Row>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col span={10} style={{display:'flex'}}>
                            <Col>
                                <Col style={{lineHeight: '1.5rem'}}><h1>{terminal_device.device_name}</h1></Col>
                                <Col>{t('terminal_device_mac_field')} : {terminal_device.device_mac}</Col>
                                <Col>{t('terminal_device_status_field')} : {terminal_device.status_text}</Col>

                            </Col>

                        </Col>
                    </Row>

                    <Divider/>
                    <Divider style={{marginTop: '10px'}}/>
                    <Row style={{display: 'flex', flexDirection: 'column'}}>
                        <Col>
                            <p style={{fontWeight: "bold", fontSize: "800"}}> {t('terminal_device_machine_list_title')}
                                </p>
                        </Col>
                        <Col>
                            <DataTable
                                locale={{emptyText: t('table_no_data_text')}}
                                sticky={true}
                                loading={reload}
                                data={terminal_device.machines}
                                columns={machineColumns}
                                pagination={true}
                            />
                        </Col>
                    </Row>
                </div>
            </>

        )
    }
    else{
        return null;
    }
}

export default TerminalDeviceDetailPage;
