import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import UserAddPage from './UserAddPage'
import http from "../../http";
import {Col, message, Row} from "antd";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";


const UserListPage = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [edit, setEdit] = useState(false);
  const [item, setItem] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (data)=>{
    return null;
  }

  const buildCardBody = (data)=>{
    const cardBodyFields = {'role_label':t('staff_role_field'), 'username': t('staff_username_field'), 'email': t('staff_email_field'), 'mobile': t('staff_mobile_field')};
    let innerContents = [];
    for (const key in cardBodyFields) {
      const nestedKeys = key.split(".");
      let value = data;
      for (const nestedKey of nestedKeys) {
        value = value[nestedKey];
        if (value === undefined) {
          break;
        }
      }
      if( key === 'role_label'){
        innerContents.push(
            <Row key={key}
                 style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{cardBodyFields[key]}: {t(value)}</span>
            </Row>
        )
      }
      else{
        innerContents.push(
            <Row
                key={key}
                style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{cardBodyFields[key]}: {value}</span>
            </Row>)
      }
    }
    const rightColumnContent = (
        <Row style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                Last Edit: {data.updated_by_text}
            </span>
        </Row>
    );
    return <Row style={{ display: 'flex', width: '100%' }} key={'cardContent'}>
      <Col span={12} style={{ paddingRight: '8px' }}>
        <Row style={{ flexDirection: 'column', justifyContent: 'start' }}>
          {innerContents}
        </Row>
      </Col>
      <Col span={12} style={{ paddingLeft: '8px' }}>
        <Row style={{ flexDirection: 'column', justifyContent: 'start' }}>
          {rightColumnContent}
        </Row>
      </Col>
    </Row>
  }

  const buildCardFooter=(data)=>{
    return null;
  }

  const buildFooterFields=(data)=>{
    return null;
  }

  const getCardBackgroundColor=(data)=>{
    if(data.is_blocked === 0){
      return '#dfede2'
    }
  }

  const config = {
    url: '/api/v1/auth/users/',
    pageTitle: t('staff_listpage_title'),
    pageSubtitle: t('staff_listpage_subtitle'),
    deleteTitle: t('staff_delete_confirmation_text'),
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'full_name',
    cardDetailButtonText: t('listpage_detail_button_text'),
    cardDeleteButtonText: 'Delete Selected',
    cardBodyFields: {'role_label':t('staff_role_field'), 'username': t('staff_username_field'), 'email': t('staff_email_field'), 'mobile': t('staff_mobile_field')},
    cardFooterFields: [<label style={{color:'#079327'}}>{t('active_label')}</label>],
    addPermission: ['user-create'],
    editPermission: ['user-edit'],
    deletePermission: ['user-delete'],
    viewPermission: ['user-detail'],
    orderingFields: [
      {id: '-username', name: t('staff_orderby_field1')},
      {id: 'username', name: t('staff_orderby_field2')},
      {id: '-first_name', name: t('staff_orderby_field3')},
      {id: 'first_name', name: t('staff_orderby_field4')},
      {id: '-created', name: t('staff_orderby_field5')},
      {id: 'created', name: t('staff_orderby_field6')},
    ],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
    'getCardBackgroundColor': getCardBackgroundColor,
  }
  const handleOpenModal = (item, edit) => {
    if(edit){
      setEdit(true);
      setItem(item);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEdit(false);
    setItem(null);
  };
  const handleSuccessAdd = () =>{
    setShowModal(false);
    setEdit(false)
    setReload(!reload);
  }

  const handleDeleteClick = (item)=>{
    http.delete(`/api/v1/auth/users/${item.id}/delete/`).then((resp)=>{
      setReload(!reload);
    }).catch((err)=>{
      if(err.response){
        message.error(err.response.data.message);
      }
    })
  }

  const handleDetailClick=(item)=>{
    navigate('/user/detail/', { replace: false, state: {user: item} })
  }

  return (
      <>
        <ListPage
            config={config}
            onAddClick={handleOpenModal}
            onEditClick={(item)=>handleOpenModal(item, true)}
            onDeleteClick={(item)=>handleDeleteClick(item)}
            onDetailClick={(item)=>handleDetailClick(item)}
            reload={reload}
        />
        <UserAddPage
            visible={showModal}
            onClose={handleCloseModal}
            onSuccessSubmit={handleSuccessAdd}
            edit={edit}
            editItem={item}
        />
      </>
  );
};

export default UserListPage;
