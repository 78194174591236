import {Divider, Row, Col, Space, Table, message, Button} from "antd";
import React, {useEffect, useState} from "react";
import http from "../../http";
import UserMachineListPage from "./UserMachineListPage";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import PermissionChecker from "../../PermissionChecker";
import DataTable from "../../components/DataTable/DataTable";
import PasswordChangePage from "./PasswordChange";

const UserProfilePage=(props)=>{
    const auth = useSelector((state) => state.auth);
    const [user, setUser] = useState({id:auth.user_id})
    const [loading, setLoading] = useState(false);
    const [assignmentDataSource, setAssignmentDataSource] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { t } = useTranslation();
    const columns = [
        {
            title: t('lease_historylist_col1'),
            dataIndex: 'contract_code',
            key: 'contract_code',
        },
        {
            title: t('lease_historylist_col2'),
            dataIndex: 'active_history',
            key: 'startDate',
            render: (text)=>(
                <div>{text.start_date}</div>
            )
        },
        {
            title: t('lease_historylist_col3'),
            dataIndex: 'active_history',
            key: 'endDate',
            render: (text)=>(
                <div>{text.end_date}</div>
            )
        },

        {
            title: t('lease_historylist_col4'),
            dataIndex: 'machine_name',
            key: 'machineName',
        },
        {
            title: t('lease_historylist_col5'),
            dataIndex: 'machine_data',
            key: 'machineId',
            render: (text)=>(
                <div>{text.identifier}</div>
            )
        },
        {
            title: t('lease_historylist_col6'),
            dataIndex: 'status_text',
            render: (text)=>(
                <div>{t(text)}</div>
            ),
            key: 'statusText',
        },
        {
            title: t('lease_historylist_col7'),
            dataIndex: 'constructor_data',
            key: 'leaseTo',
            render: (text) => (
                <div>{(text.company_name)}</div>
            ),
        },
    ]

    const fetchUserData=(userID)=>{
        http.get(`/api/v1/auth/users/${userID}/profile/`).then((resp)=>{
            setUser(resp.data)
        }).catch((err)=>{
            message.error(t('error_fetching_user_profile'))
        })
    }
    const fetchDefaultData =()=>{
        let userID = auth.user_id;
        setLoading(true);
        http.get(`/api/v1/machines/constructor_machines/?machine__assigned=${userID}`).then((resp)=>{
            setAssignmentDataSource(resp.data.results);
            setLoading(false);
        }).catch(()=>{
            setLoading(false);
        })
        fetchUserData(userID);
    }

    useEffect(()=>{
        fetchDefaultData()
    }, [])

    const buildBreadcrumbs=()=>{
        return t('menu_0');
    }
    const renderPasswordChangeForm = () => {
        return (
            <PasswordChangePage
                visible={isModalVisible}
                onClose={()=>{setIsModalVisible(false)}}
            />
        );
    };

    return(
        <>
            {renderPasswordChangeForm()}
            <div style={{overflowY:'auto', overflowX:'hidden', width: '100%', height:'100%'}}>
                <Row style={{height: '5%', color: "black", fontWeight:'bold', justifyContent:'space-between'}}>
                    <Col>
                        <Space>{buildBreadcrumbs()}</Space>
                    </Col>
                    <Col>
                        {user.is_initial === 0 ?<Button
                            type={'primary'}
                            onClick={() => setIsModalVisible(true)}>{t('profile_change_password_button_text')}
                        </Button>:null}
                    </Col>
                </Row>
                <Row style={{display: 'flex', flexDirection:'column'}}>
                    <Col style={{lineHeight: '1.5rem'}}><h1>{user.full_name}</h1></Col>
                    <Col>{t('staff_email_field')} : {user.email}</Col>
                    <Col>{t('staff_mobile_field')} : {user.mobile}</Col>
                    <Col>{t('staff_slackid_field')} : {''}</Col>
                </Row>
                <Divider />
                <PermissionChecker requiredPermissions={['machine-list']}>
                    <Row style={{overflow: "hidden", height: '60%'}}>
                        <div style={{width: '100%', height: '100%'}}>
                            <UserMachineListPage pageSize={3} user={user}/>
                        </div>
                    </Row>
                </PermissionChecker>
                    <Divider style={{marginTop: '10px'}}/>
                    <PermissionChecker requiredPermissions={['constructor-machine-list']}>
                        <Row style={{display: 'flex', flexDirection:'column'}}>
                            <Col>
                                <p style={{fontWeight: "bold", fontSize: "800"}}>  {t('lease_historylist_title')} </p>
                            </Col>
                            <Col>
                                <DataTable
                                    locale={{ emptyText: t('table_no_data_text') }}
                                    sticky={true}
                                    loading={loading}
                                    data={assignmentDataSource}
                                    columns={columns}
                                    pagination={true}
                                />
                            </Col>
                        </Row>
                    </PermissionChecker>
            </div>
        </>

)
}

export default UserProfilePage;
