import React, { useState, useEffect } from 'react';
import { Modal, Input, Button } from 'antd';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { fromLonLat } from 'ol/proj';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import markerIcon from "../../assets/icons/marker.png";
import { transform } from 'ol/proj';

const CustomLocationInput = ({ value = '', onChange, placeholder = 'Select a location' }) => {
  const [visible, setVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(value);
  const [map, setMap] = useState(null);
  const [vectorSource, setVectorSource] = useState(null);

  const openMap = () => setVisible(true);
  const closeMap = () => setVisible(false);

  useEffect(() => {
    if (visible && !map) {
      const vector = new VectorSource();
      setVectorSource(vector);
      const initialCoordinates = value? value.split(',').map(Number) : fromLonLat([139.6917, 35.6895]);
      const initializedMap = new Map({
        target: 'map_add',
        layers: [
          new TileLayer({
            source: new XYZ({
              url: 'https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png',
            }),
          }),
          new VectorLayer({
            source: vector,
          }),
        ],
        view: new View({
          center: initialCoordinates, // Default to Tokyo
          zoom: 15,
        }),
      });

      initializedMap.on('click', (event) => handleMapClick(event, initializedMap, vector));
      setMap(initializedMap);
    }
  }, [visible]);

  useEffect(() => {
    if (value && vectorSource) {
      const [lon, lat] = value.split(',').map(Number);
      placeMarker(lon, lat);
    }
  }, [value, vectorSource]);

  const handleMapClick = (event, map, vectorSource) => {
    if (!vectorSource) return; // Prevent errors if vectorSource is null

    const [lon, lat] = map.getCoordinateFromPixel(event.pixel);

    placeMarker(lon, lat);
    const formattedLocation = `${lon.toFixed(6)}, ${lat.toFixed(6)}`;
    setSelectedLocation(formattedLocation);
    onChange(formattedLocation); // Update form value
  };

  const placeMarker = (lon, lat) => {
    if (!vectorSource) return; // Ensure vectorSource is valid

    vectorSource.clear(); // Clear previous markers

    const marker = new Feature({
      geometry: new Point([lon, lat]),
    });

    marker.setStyle(
      new Style({
        image: new Icon({
          src: markerIcon,
          scale: 0.08,
        }),
      })
    );
    vectorSource.addFeature(marker);
  };

  return (
    <>
      <Input
        value={value || selectedLocation}
        readOnly
        placeholder={placeholder}
        addonAfter={<Button onClick={openMap}>Select Location</Button>}
      />
      <Modal
        title="Select Location"
        visible={visible}
        onCancel={closeMap}
        onOk={closeMap}
        width={800}
        footer={[
          <Button key="cancel" onClick={closeMap}>
            Cancel
          </Button>,
          <Button key="select" type="primary" onClick={closeMap}>
            Select
          </Button>,
        ]}
      >
        <div
          id="map_add"
          style={{
            width: '100%',
            height: '500px',
            position: 'relative',
          }}
        />
      </Modal>
    </>
  );
};

export default CustomLocationInput;