import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import http from "../../http";
import {message, Row, Col, Tooltip, Button} from "antd";
import {RetweetOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CustomConfirmPopup} from "../../components/CustomConfirmPopup/CustomConfirmPopup";
import {EditOutlined, PlusOutlined, CloseOutlined} from "@ant-design/icons";
import PermissionChecker from "../../PermissionChecker";
import DeleteIcon from "../../components/CustomIcons/DeleteIcon";


const TerminalDeviceListPage = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [edit, setEdit] = useState(false);
  const [item, setItem] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const buildPageHeader = (data) =>{
    return null;
  }
  const buildCardHeader = (item)=>{
        let content = <Row className={'cardHeader'} style={{alignItems:'center'}} key={'cardHeader'}>
            <Tooltip title={item? item[config.cardTitleField]: ''} placement={'bottomLeft'}>
                <Col key={'cardTitle'} span={16} style={{whiteSpace: 'nowrap', fontWeight: 'bold', fontSize:'20px', overflow:'hidden', textOverflow:'ellipsis'}}>{item? item[config.cardTitleField]: ''}</Col>
            </Tooltip>
            <Col key={'cardButtons'} span={8} style={{display:'flex', flexDirection:'row', justifyContent:'space-around', alignItems:'center'}}>
                {!item.expired ?
                    <CustomConfirmPopup
                        title={config.deleteTitle || "Are you sure to delete this instance?"}
                        onConfirm={()=>handleDeleteClick(item)}
                        icon={<DeleteIcon />}
                        okText={t('yes_text')}
                        cancelText={t('no_text')}
                        okType='default'
                    >
                        <PermissionChecker
                            requiredPermissions={config.deletePermission || []}>
                            <Button
                            className={'card-icon card-edit-icon'}
                            icon={<DeleteIcon />}
                            style={
                                {
                                    border: 'none',
                                    color:'#377EF3'
                                }
                                }
                            />
                        </PermissionChecker>
                    </CustomConfirmPopup>:<CustomConfirmPopup
                        title={t('device_re_registration_confirm_text')}
                        onConfirm={()=>handleRestoreClick(item)}
                        icon={<RetweetOutlined />}
                        okText={t('yes_text')}
                        cancelText={t('no_text')}
                        okType='default'
                    >
                        <PermissionChecker
                            requiredPermissions={['restore-terminal-device']}>
                            <Button
                            className={'card-icon card-edit-icon'}
                            icon={<RetweetOutlined />}
                            style={
                                {
                                    border: 'none',
                                    color:'#377EF3'
                                }
                                }
                            />
                        </PermissionChecker>
                    </CustomConfirmPopup>}

            </Col>
        </Row>;
    return content;
  }

  const buildCardBody = (data)=>{
    return null;
  }

  const renderFooterFields=(data)=>{
      return [<label style={{ wordWrap: 'break-word', wordBreak: 'break-all', color: data.expired_status === 1 ?
            '#DC000C': '#079327'}}>{data.status_text}</label>]
   }

   const buildFooterFields=(data)=>{
       return renderFooterFields(data);
   }

  const buildCardFooter=(data)=>{
    return null;
  }

  const config = {
    url: '/api/v1/auth/tenants/terminal_devices/',
    pageTitle: t('terminal_device_listpage_title'),
    pageSubtitle: t('terminal_device_listpage_subtitle'),
    searchPlaceHolder: t('listpage_search_text') +'...',
    cardTitleField: 'device_name',
    cardDetailButtonText: t('listpage_detail_button_text'),
    cardDeleteButtonText: 'Delete Selected',
    deleteTitle: t('device_de_registration_confirm_text'),
    cardBodyFields: {
      'device_name': t('terminal_device_name_field'),
      'device_mac': t('terminal_device_mac_field'),
    },
    hideAddButton: true,
    hideEdit: true,
    cardFooterFields: [],
    deletePermission: ['expire-terminal-device'],
    viewPermission: ['terminal-device-detail'],
    'buildPageHeader': buildPageHeader,
    'buildCardHeader': buildCardHeader,
    'buildCardBody': buildCardBody,
    'buildCardFooter': buildCardFooter,
    'buildFooterFields': buildFooterFields,
  }


  const handleDeleteClick = (item)=>{
        http.post(`/api/v1/auth/tenants/terminal_devices/${item.id}/expire_terminal_device/`).then((resp)=>{
            if (resp.status === 200) {
                message.success(t(resp?.data?.message))
                setReload(!reload);
            }
            else{
                setReload(!reload);
                message.error(t(resp?.data?.message))
            }
        }).catch((err)=>{
            setReload(!reload);
            message.error(t("terminal_device_de_registration_failed_text"))
        })
  }

  const handleRestoreClick=(item)=>{
      http.post(`/api/v1/auth/tenants/terminal_devices/${item.id}/restore_terminal_device/`).then((resp)=>{
                if (resp.status === 200) {
                    message.success(t(resp?.data?.message))
                    setReload(!reload);
                }
                else{
                    setReload(!reload);
                    message.error(t(resp?.data?.message))
                }
            }).catch((err)=>{
                setReload(false);
                message.error(t("terminal_device_re_registration_failed_text"))
            })
  }
  const handleDetailClick=(item)=>{
    navigate('/terminal_device/detail/', { state: {terminal_device: item} })
  }

  return (
      <>
        <ListPage
            config={config}
            onDeleteClick={(item)=>handleDeleteClick(item)}
            onDetailClick={(item)=>handleDetailClick(item)}
            reload={reload}
        />
      </>
  );
};

export default TerminalDeviceListPage;
