import React, {useState} from "react";
import ListPage from "../../components/ListPage/ListPage";
import MachineAddPage from "./MachineAddPage";
import http from "../../http";
import {useNavigate} from "react-router-dom";
import {Col, message, Tooltip} from 'antd';
import {useTranslation} from "react-i18next";
import {Row} from 'antd';


const MachineListPage = (props) => {

    const { t } = useTranslation();

    const buildPageHeader = (data) =>{
        return null;
    }
    const buildCardHeader = (data)=>{
        return null;
    }

    const buildCardBody = (data)=>{
        console.log(data)
        const cardBodyFields = {
            'number': t('machine_carnumber_field'),
            'machine_type_text':  t('machine_type_field'),
            'vrs_id':  t('machine_vrs_field'),
        };
        if(data.has_running_contract){
            cardBodyFields['running_contract_constructor'] = t('machine_list_rental_constructor_label')
            cardBodyFields['running_contract_date'] = t('machine_list_rental_period_label')
        }
        else{
            cardBodyFields['running_contract_constructor'] = t('')
            cardBodyFields['running_contract_date'] = t('')
        }
        let innerContents = [];
        const rightColumnContent = (
            <Row style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Tooltip title={`${t('last_edit_label')}: ${data.updated_by_text}`}>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {t('last_edit_label')}: {data.updated_by_text}
                    </span>
                </Tooltip>
            </Row>
        );
        if(cardBodyFields) {
            for (const key in cardBodyFields) {
                const nestedKeys = key.split(".");
                let value = data;
                for (const nestedKey of nestedKeys) {
                    value = value[nestedKey];
                    if (value === undefined) {
                        break;
                    }
                }
                if( key === 'machine_type_text'){
                    innerContents.push(
                        <Row key={key} style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <Tooltip title={`${cardBodyFields[key]}: ${t(value)}`}>
                                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {cardBodyFields[key]}: {t(value)}
                                </span>
                            </Tooltip>
                        </Row>
                    )
                }
                else{
                    innerContents.push(
                        <Row key={key} style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <Tooltip title={`${cardBodyFields[key]}: ${value}`}>
                                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {value || key === 'vrs_id' ? `${cardBodyFields[key]}: ${value}` : (
                                        <span style={{ visibility: 'hidden' }}>
                                            {cardBodyFields[key]}: placeholder
                                        </span>
                                    )}
                                </span>
                            </Tooltip>
                        </Row>
                    )
                }
            }
        }
        return <Row style={{ display: 'flex', width: '100%' }} key={'cardContent'}>
            <Col span={12} style={{ paddingRight: '8px' }}>
                <Row style={{ flexDirection: 'column', justifyContent: 'start' }}>
                    {innerContents}
                </Row>
            </Col>
            <Col span={12} style={{ paddingLeft: '8px' }}>
                <Row style={{ flexDirection: 'column', justifyContent: 'start' }}>
                    {rightColumnContent}
                </Row>
            </Col>
        </Row>
    }

    const buildCardFooter=(data)=>{
        return null;
    }

    const renderFooterFields=(data)=>{
        if(data.license_status !== 2){
            return [<label style={{color:'#377EF3'}}>{t('machine_registration_pending_status_text')}</label>]
        }
        return [<label style={{color:data.lease_status ===1 ? '#CE7F00': '#079327'}}>{t(`${data.lease_status_text}`)}</label>]
    }

    const buildFooterFields=(data)=>{
        return renderFooterFields(data);
    }

    const config = {
        url: '/api/v1/machines/',
        pageTitle: t('machinelist_title'),
        pageSubtitle: t('machinelist_subtitle'),
        deleteTitle: t('machine_delete_confirmation_text'),
        searchPlaceHolder: t('listpage_search_text') +'...',
        cardTitleField: 'name',
        cardDetailButtonText: t('listpage_detail_button_text'),
        cardDeleteButtonText: 'Delete Selected',
        cardBodyFields: {'number': t('machine_carnumber_field'), 'machine_type_text': t('machine_type_field')},
        cardFooterFields: [],
        addPermission: ['machine-create'],
        editPermission: ['machine-edit'],
        deletePermission: ['machine-delete'],
        viewPermission: ['machine-detail'],
        orderingFields: [
            {id: '-name', name: t('machine_orderby_field1')},
            {id: 'name', name: t('machine_orderby_field2')},
            {id: '-number', name: t('machine_orderby_field3')},
            {id: 'number', name: t('machine_orderby_field4')},
            {id: '-created', name: t('machine_orderby_field5')},
            {id: 'created', name: t('machine_orderby_field6')},
        ],
        'buildPageHeader': buildPageHeader,
        'buildCardHeader': buildCardHeader,
        'buildCardBody': buildCardBody,
        'buildCardFooter': buildCardFooter,
        'buildFooterFields': buildFooterFields,
    }
    const [showModal, setShowModal] = useState(false);
    const [reload, setReload] = useState(false);
    const [edit, setEdit] = useState(false);
    const [item, setItem] = useState(false);
    const navigate = useNavigate();
    const handleOpenModal = (item, edit) => {
        if(edit){
            setEdit(true);
            setItem(item);
            setShowModal(true);
        }
        else {
            setEdit(false);
            setItem(null);
            setShowModal(true);

        }
    };

    const handleCloseModal = () => {
        setEdit(false);
        setItem(null);
        setShowModal(false);

    };
    const handleSuccessAdd = () =>{
        setEdit(false);
        setItem(null);
        setShowModal(false);
        setReload(!reload);
    }

    const handleDeleteClick = (item)=>{
        if(item.lease_status === 0){
            http.delete(`/api/v1/machines/${item.id}/delete/`).then((resp)=>{
                if(resp.status===204){
                    message.success(t('machine_delete_successful_message'));
                    setReload(!reload);
                }
                else{
                    let message_text =  resp.data.message || resp.data.error || resp.data.success;
                    message.error(t(message_text))
                }
            }).catch((err)=>{
                message.error(t('generic_server_error_text'))
            })
        }
        else{
            message.error(t('machine_delete_validation_text'))
        }
    }

    const handleDetailClick=(item)=>{
        navigate('/machine/detail/', { replace: true, state: {machine: item} })
    }

    return (
        <>
            <ListPage
                config={config}
                onAddClick={handleOpenModal}
                onEditClick={(item)=>handleOpenModal(item, true)}
                onDeleteClick={(item)=>handleDeleteClick(item)}
                onDetailClick={(item)=>handleDetailClick(item)}
                reload={reload}
            />
            <MachineAddPage
                visible={showModal}
                onClose={handleCloseModal}
                onSuccessSubmit={handleSuccessAdd}
                edit={edit}
                editItem={item}
            />
        </>
    );
};

export default MachineListPage;
